const  router = [
	{
		path: "/account/layout",
		component: () => import('../../../pages/account/Layout.vue'),
		meta: {title: '账号管理'},
		name: 'account_layout',
		children:[
			{
				path: "/account",
				component: () => import('../../../pages/account/list.vue'),
				meta: {title: '账号列表'},
				name: 'account_list',
			},
			{
				path: "/account/firm",
				component: () => import('../../../pages/account/firm.vue'),
				meta: {title: '公司列表'},
				name: 'account_firm'
			},
			{
				path: "/account/customerService",
				component: () => import('../../../pages/account/customerService.vue'),
				meta: {title: '客服列表'},
				name: 'account_service'
			},
		]
	}
];

export default router